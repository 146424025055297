.ui{
  & .aboutus.-override{
    //background-image: url("../../img/about-us.png");
    //background-repeat: no-repeat;
    //background-size: cover;
    //background-position-y: 34%;
    color: $color-white;
    overflow: hidden;
    & > img{
      position: absolute;
      top: -170%;
      left: 0;
      width: 100%;
      filter: brightness(0.5);
    }
    & .header{
      color: $color-white;
    }

  }
}
.ui.monster.section.dividing.header, .ui.right.floated.small.header {
  font-family: 'Nunito', sans-serif !important;
  font-weight: 300 !important;
  color: #CCB36E !important;
  text-transform: uppercase;
  font-size: 21pt;
  letter-spacing: 3px;
  &.sorting,
  &.paginate{
    font-size: 1.1rem;
  }
  .text {
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500 !important;
    color: #CCB36E !important;
    text-transform: uppercase;
  }
}

.ui.section.divider.-override{
  margin-top: 5rem;
  margin-bottom: 5rem;
}
#searchbarButtons {
  .ui.labeled.button.-override, .ui.primary.button.-override {
    font-size: 13px !important;
  }

  .ui.form input[type=text] {
    border-radius: 0;

    &:focus {
      border-radius: 0;
      border-color: inherit;
    }
  }
}


#descriptionTaxon{
  margin-top: 5rem;
  font-family: Montserrat, sans;
  font-weight: 400;
  font-size: 18px;
  text-transform: initial;
}
.ui{
  & .submit.button.-override{
    margin-top: 30px;
  }
  & .ui.right.floated.-override{
    color: black;
    text-decoration: underline;
    vertical-align: bottom;
  }
  & .ui.basic.icon.mini.button.-override:hover{
    background: none;
    color: $color-ocre;
    box-shadow: none;
  }
}
.ui.header.connexionHeader{
  margin-top: 3rem;
}

.cta-button {
  font-size: rem($font-body-l);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  .ui-icon {
    margin: 0 rem(12) 0 rem(-12);
  }
  &.-variant-1 {
    background-color: #ffffff;
    color: $color-argil;
    border: 1px solid $color-argil;
  }
  &.-variant-2 {
    background-color: $color-black;
    color: white;
    .ui-icon {
      --color-icon: #{$color-white};
    }
  }
  &.-variant-3 {
    background-color: $color-white;
    border-color: transparent;
    color: white;
  }
  &.-variant-4 {
    background-color: transparent;
    border-color: transparent;
    .ui-icon {
      --color-icon: #{$color-argil};
    }
  }
  &.-variant-5 {
    background-color: $color-ocre;
    border-color: transparent;
    color: #{$color-white};
    .ui-icon {
      --color-icon: #{$color-white};
    }
  }
  &.-size-50 {
    height: rem($spacing-050);
    &.-larg-small {
      min-width: rem(50);
    }
  }
  &.-size-32 {
    height: rem($spacing-032);
    &.-larg-small {
      min-width: rem(32);
    }
  }
  &.-larg-default {
    padding: 0 rem($spacing-032);
  }
  &.-larg-small {
    padding: 0 rem($spacing-008);
  }
  &.-larg-full {
    width: 100%;
  }

  &.-icononly {
    .ui-icon {
      margin: 0;
    }
  }

  &.-social-login {
    background-color: $color-dark-silver;
    border: none;
    justify-content: left;
    padding: 0 rem($spacing-016);
    width: 100%;
    .ui-icon {
      min-width: rem(42);
    }
    span {
      border-left: rem(1) solid $color-grey;
      color: $color-argil;
      font-size: rem(13);
      margin-left: rem($spacing-016);
      padding-left: rem($spacing-016);
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

.ui {

  &.header {
    color: $color-ocre;
    & .newsletter-description{
      font-size: 8pt;
    }
  }

  &.fluid {
    &.banner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      background-color: $color-ocre;
      padding: 0 5%;

      & div, img {
        width: 50%;
      }

      & div {
        color: $color-white;

        & h2 {
          font-size: 3rem;
        }

        & p > span {
          font-weight: bold;
          font-size: 1.2rem;
        }

        & a {
          color: $color-white;
          border: 1px solid $color-white;
          padding: 7px 17px;
          border-radius: 4px;
        }
      }
    }
  }
  & .homepage {
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      margin-top: -30px;
    }
  }
  & .taxon-cube-page {
    text-align: center;
    margin: 5% 0;

    & > .ui-wrapper > h2 {
      font-family: $font-family-sans;
      font-weight: 400;
      font-size: 21pt;
      margin-bottom: 3%;
      text-transform: uppercase;
      letter-spacing: 10px;
    }

    & .homepage-tiles {
      //width: 90%;
      margin: 0 auto;
      max-width: 1280px;
      padding: 0 5%;
      display: grid;
      margin: auto;

      & .homepage-tile-1,
      & .homepage-tile-2,
      & .homepage-tile-3,
      & .homepage-tile-4,
      & .homepage-tile-5,
      & .homepage-tile-6{
        position: relative;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition: 1s all;
          object-fit: cover;
        }

        & > p {
          font-family: $font-family-sans;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid white;
          padding: 15px 35px;
          font-weight: 400;
          color: $color-argil;
          font-size: 15pt;
          text-transform: uppercase;
        }
        &:hover{
          & > a {
            background-color: #FFFFFF;
          }
          img{
            transform: scale(1.1);
            transition: 1s all;
          }
        }
      }
    }
    .tiles-4{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 26px;
      grid-row-gap: 24px;
      @media screen and (max-width: 768px) {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: 1fr!important;
        grid-column-gap: 0!important;
        grid-row-gap: 10px!important;
      }
      & .homepage-tile-1 {
        grid-area: 1 / 1 / 3 / 2;
      }

      & .homepage-tile-2 {
        grid-area: 1 / 2 / 2 / 3;
      }

      & .homepage-tile-3 {
        grid-area: 2 / 2 / 3 / 3;
      }

      & .homepage-tile-4 {
        grid-area: 1 / 3 / 3 / 4;
      }
      @media screen and (max-width: 768px) {
        & .homepage-tile{
          &-1 { grid-area: 1 / 1 / 2 / 2; }
          &-2 { grid-area: 2 / 1 / 3 / 2; }
          &-3 { grid-area: 3 / 1 / 4 / 2; }
          &-4 { grid-area: 4 / 1 / 5 / 2; }
        }
      }
    }
    .tiles-5{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 26px;
      grid-row-gap: 24px;
      @media screen and (max-width: 768px) {
        grid-template-rows: repeat(5, 1fr);
        grid-template-columns: 1fr!important;
        grid-column-gap: 0!important;
        grid-row-gap: 0!important;
      }
      height: 700px;

      & .homepage-tile-1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      & .homepage-tile-2 {
        grid-area: 2 / 1 / 3 / 2;
      }

      & .homepage-tile-3 {
        grid-area: 1 / 2 / 3 / 3;
      }

      & .homepage-tile-4 {
        grid-area: 1 / 3 / 2 / 4;
      }
      & .homepage-tile-5 {
        grid-area: 2 / 3 / 3 / 4;
      }
      @media screen and (max-width: 768px) {
        & .homepage-tile{
          &-1 { grid-area: 1 / 1 / 2 / 2; }
          &-2 { grid-area: 2 / 1 / 3 / 2; }
          &-3 { grid-area: 3 / 1 / 4 / 2; }
          &-4 { grid-area: 4 / 1 / 5 / 2; }
          &-5 { grid-area: 5 / 1 / 6 / 2; }
        }
      }
    }

    .tiles-6{
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 26px;
      grid-row-gap: 24px;
      height: 700px;
      @media screen and (max-width: 768px) {
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: 1fr!important;
        grid-column-gap: 0!important;
        grid-row-gap: 0!important;
      }

      & .homepage-tile-1 {
        grid-area: 1 / 1 / 3 / 2;
      }

      & .homepage-tile-2 {
        grid-area: 1 / 2 / 2 / 3;
      }

      & .homepage-tile-3 {
        grid-area: 2 / 2 / 3 / 3;
      }

      & .homepage-tile-4 {
        grid-area: 1 / 3 / 2 / 4;
      }

      & .homepage-tile-5 {
        grid-area: 3 / 1 / 4 / 3;
      }
      & .homepage-tile-6 {
        grid-area: 2 / 3 / 4 / 4;
      }
      @media screen and (max-width: 768px) {
        & .homepage-tile{
          &-1 { grid-area: 1 / 1 / 2 / 2; }
          &-2 { grid-area: 2 / 1 / 3 / 2; }
          &-3 { grid-area: 3 / 1 / 4 / 2; }
          &-4 { grid-area: 4 / 1 / 5 / 2; }
          &-5 { grid-area: 5 / 1 / 6 / 2; }
          &-6 { grid-area: 6 / 1 / 7 / 2; }
        }
      }
    }
  }

  & .products-select-track {
    position: relative;
    text-align: center;
    margin: 5% auto;

    & > .ui-wrapper > h2 {
      font-family: $font-family-sans;
      font-weight: 400;
      font-size: 21pt;
      margin-bottom: 3%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 10px;
    }

    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 80%;
      background: $color-off-white;
      bottom: -20px;
      z-index: -1;

      box-shadow: 0 40vw 0 40vw  $color-off-white;
      -webkit-clip-path: inset(0 -40vw 0 -40vw);
      left: 0;
      bottom: 0;
      pointer-events: none;
    }

    &-selection-container {
      margin-top: 84px;
      padding-bottom: 60px;
    }

    &-selection {
      font-family: $font-family-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 13pt;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #000000;
      padding: 20px 100px;
      &:hover{
        background: #CCB36E;
      }
    }

    & .products-track {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-between;
      align-items: flex-start;
      //width: 90%;
      //margin: 0 auto;
      margin: 0 auto;
      max-width: 1280px;
      padding: 0 5%;

      &-item {
        flex: 0 0 30%;
        text-align: center;
        a{
          color: black;
        }
        &-image{
          //height: 380px;
          overflow: hidden;
        }
        img{
          width: 100%;
          height: 100%;
          transition: .3s all ease;
        }
        &-container {
          text-align: left;

          &-desc {
            margin: 10px 0;
            p{
              font-size: 13pt;
            }
          }
          &-title{
            margin-top: 10px;
          }

          &-desc > p,
          &-price > del,
          &-price > span,
          &-title > h3 {
            font-family: $font-family-secondary-sans;
            font-weight: 400;
          }
          &-desc > p {
            font-size: 14px;
          }

          &-title > h3{
            //font-size: 20pt;
            font-size: 18px;
            font-weight: 400;
            span{
              color: #CCB36E;
            }
          }
          &-price{
            color: #CCB36E;
          }
          &-price > del{
            color: black;
          }
          &-price > del,
          &-price > span {
            //font-size: 1.7rem;
            font-size: 18px;
          }
        }
        &:hover{
          img{
            transition: .3s all ease;
            transform: scale(1.1);
          }
        }
      }
    }
  }

  & .articles-select-track {
    position: relative;
    text-align: center;
    margin: 5% auto;

    & > .ui-wrapper > h2 {
      font-family: $font-family-sans;
      font-weight: 400;
      font-size: 21pt;
      margin-bottom: 3%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 10px;
    }

    &-selection-container {
      margin-top: 84px;
      padding-bottom: 60px;
    }

    &-selection {
      font-family: $font-family-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 13pt;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #000000;
      padding: 20px 100px;
      &:hover{
        background: #CCB36E;
      }
    }
    .-inViewArticles{
      .articles-track-item{
        transform: translateX(0px);
        opacity: 1;
        &:nth-child(2){
          transform: translateX(0px);
        }
      }
    }
    & .articles-track {
      //width: 60%;
      //margin: 0 auto;
      margin: 0 auto;
      max-width: 1280px;
      padding: 0 5%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-between;
      align-items: flex-start;
      &-item {
        transform: translateX(-100px);
        opacity: 0;
        transition: 1s all ease;
        flex: 0 0 45%;
        text-align: center;
        &:nth-child(2){
          transform: translateX(100px);
        }
        img{
          width: 100%;
          object-fit: cover;
          height: 500px;
        }
        &-container {
          text-align: left;

          &-desc {
            margin: 10px 0;
            p{
              font-size: 13pt;
            }
          }

          &-desc > p,
          &-title > h3 {
            font-family: $font-family-secondary-sans;
            font-weight: 400;
          }
          &-desc > p {
            font-size: 14px;
          }
          &-title > h3 {
            margin-top: 30px;
            //font-size: 20pt;
            font-size: 18px;
          }

          &-more > a {
            font-family: $font-family-sans;
            font-style: normal;
            text-transform: uppercase;
            font-weight: 200;
            //font-size: 13pt;
            font-size: 14px;
            color: black;
            text-decoration: underline;
            &:hover{
              color: $color-ocre;
            }
          }
        }
      }
    }
  }
  .-inViewConcept{
    .concept-block-image{
      transform: translate(-10%,0px);
      &:nth-child(1){
        transform: translate(-10%,45%);
      }
    }
  }
  & .concept-block {
    &-container {
      margin: 0 auto;
      //max-width: 1280px;
      background: #889380;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: flex-start;
      padding: 5%;
      position: relative;
      &:before{
        box-shadow: 0 40vw 0 40vw rgba(136, 147, 128, 1);
        -webkit-clip-path: inset(0 -40vw 0 -40vw);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        pointer-events: none;
      }
    }

    &-image {
      flex: 0 0 30%;
      transform: translate(-10%,-30%);
      transition: 1s all ease;
      img{
        width: 100%;
        height: 100%;
      }
      &:nth-child(1){
        transform: translate(-10,85%);
      }
    }

    &-content {
      margin-top: 5%;
      flex: 0 0 35%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      &-title{
        font-family: $font-family-sans;
        font-style: normal;
        font-weight: 400;
        font-size: 21pt;
        line-height: 34px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #FFF;
        padding-bottom: 9%;
        h3{
          font-size: 25pt;
        }
        p{
          font-size: 15pt;
        }
      }
      &-desc{
        p{
          font-family: $font-family-secondary-sans;
          font-style: normal;
          font-weight: 400;
          font-size: 13pt;
          line-height: 24px;
          color: #FFF;
          text-align: left;
        }
      }
      &-link{
        font-family: $font-family-sans;
        font-style: normal;
        font-weight: 400;
        font-size: 12pt;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #000000;
        padding: 20px 0;
        margin-top: 15%;
        display: block;
        &:hover{
          background: #CCB36E;
        }
      }
    }

  }

  & .instagram-block-container{
    display: flex;
    color: black;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    //width: 90%;
    margin: 15% auto 10%;
    //margin: 0 auto;
    max-width: 1280px;
    padding: 0 5%;
    &-content{
      & h3{
        font-family: $font-family-sans;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 35px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        width: 90%;
        text-align: left;
      }
      & p{
        display: block;
        background: #889380;
        padding: 20px;
        font-family: Nunito, sans;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: white;
        width: 60%;
      }
    }
    &-image{
      flex: 0 0 60%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 5px;
      grid-row-gap: 5px;
      img{
        width: 100%;
      }
      &-secondary{
        &-1 { grid-area: 1 / 1 / 3 / 3 }
        &-2 { grid-area: 1 / 3 / 2 / 4; }
        &-3 { grid-area: 1 / 4 / 2 / 5; }
        &-4 { grid-area: 2 / 3 / 3 / 4; }
        &-5 { grid-area: 2 / 4 / 3 / 5; }
      }
    }
  }

  & .newsletter.-override{
    margin: 0 auto;
    max-width: 1280px;

    background: $color-off-white;
    color: $color-black;
    padding: 10% 5%;
    border: none;
    position: relative;
    & h2.ui.huge.header {
      color: $color-black;
      font-family: $font-family-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 25pt;
      line-height: 35px;
      /* or 175% */

      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    & .newsletter-input input{
      background: transparent;
      border: none;
      border-bottom: 1px solid $color-black;
      border-radius: 0;
      &::placeholder{
        color: black;
        font-family: $font-family-secondary-sans;
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
      }
    }

    & div.newsletter-button > button {
      font-family: $font-family-sans;
      background: $color-black;
      color: $color-white;
      //border: 1px solid white;
      padding: 11px 17px;
      border-radius: 0;
      font-weight: 400;
      text-transform: uppercase;
    }

    &:before{
      box-shadow: 0 40vw 0 40vw rgba(237, 236, 233, 1);
      -webkit-clip-path: inset(0 -40vw 0 -40vw);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
      pointer-events: none;
    }
  }

  & .info-block-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    margin: 7% auto;
    max-width: 1280px;
    padding: 0 5%;

    & .info-block-item {
      text-align: center;
      min-width: 270px;
      img{
        height: 71px;
        width: 81px;
        margin-bottom: .5rem;
      }

    }


  }


}

@media screen and (max-width: 1025px){
  .homepage{
    .taxon-cube-page{
      .ui-wrapper{
        &:nth-child(1){
          h2{
            margin: 10% auto 5%;
          }
        }
      }
    }
    .products-select-track{
      margin: 10% auto;
      position: relative;
      padding: 15% 0;
      &-selection{
        font-size: 8pt;
        padding: 20px 70px;
      }
      .products-track{
        flex-direction: column;
        gap: 3rem;
        padding: 0;
      }
      &:after{
        box-shadow: 0 40vw 0 40vw #EDECE9;
        -webkit-clip-path: inset(0 -40vw 0 -40vw);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        pointer-events: none;
      }
    }
    .articles-select-track{
      margin: 10% auto;
      &-selection{
        font-size: 8pt;
        padding: 20px 70px;
      }
      .articles-track{
        flex-direction: column;
        gap: 3rem;
        padding: 0;
        &-item{
          opacity: 1;
          transform: translateX(0) !important;
        }
      }
    }
    .testimony{
      margin: 10% auto;
      &_header{
        margin-bottom: 10%;
      }
    }
    .instagram{
      margin: 10% auto;
      a{
        flex-direction: column;
        gap: 3rem;
        padding: 0;
      }
    }
    .info-block-container{
      margin: 10% auto;
      padding: 0;
      flex-direction: column;
      gap: 3rem;
    }
    .newsletter{
      .ui.form{
        flex-direction: column;
        gap: 1rem;
        .newsletter-button{
          button{
            width: 100%;
            font-size: 8pt !important;
          }
        }
      }
    }
  }
  .ui .concept-block-container{
    margin: 10% auto;
    padding: 10% 0;
    flex-direction: column;
    .concept-block-content-link{
      font-size: 8pt;
    }
    .concept-block-image{
      width: 100%;
      transform: translateY(0);
    }
  }
}

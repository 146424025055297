.sixteen.wide.mobile.wide.computer.column{
  .ui.segment{
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .header{
    font-family: 'Nunito', sans-serif !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.87);
    text-transform: uppercase;
    font-size: 18pt;
    letter-spacing: 3px;
  }
  .description{
    font-family: Montserrat, sans;
    font-style: normal;
    font-weight: 400;
    font-size: 13pt;
    line-height: 24px;
    color: #000;
  }
}

.sixteen.wide.mobile.twelve.wide.computer.column{
  .ui.segment{
    background: transparent;
    border: none;
    box-shadow: none;
  }
  h1{
    font-family: 'Nunito', sans-serif !important;
    font-weight: 400 !important;
    color: #CCB36E !important;
    text-transform: uppercase;
    font-size: 21pt;
    letter-spacing: 3px;
  }
  .description{
    h1{
      color: rgba(0,0,0,.87) !important;
    }
    h2{
      font-family: 'Nunito', sans-serif !important;
      font-weight: 400 !important;
      color: rgba(0,0,0,.87);
      text-transform: uppercase;
      font-size: 21pt;
      letter-spacing: 3px;
    }
    a{
      color: rgba(0,0,0,.87);
      text-decoration: underline;
    }
  }
}

.articles-list{
  //display: grid;
  //grid-template-columns: repeat(2, 1fr);
  //grid-column-gap: 30px;
  //grid-row-gap: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 5%;
  .item{
    flex: 0 0 45%;
    display: flex;
    padding: 0 !important;
    border-top: unset !important;
    flex-direction: column;
    gap: 30px;
    .image{
      width: 100% !important;
      height: 500px;
      img{
        height: 100% !important;
      }
    }
  }
  .content{
    padding: 0 !important;
  }
  .articleBtn{
    font-family: Nunito, sans;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 200;
    font-size: 14px;
    color: black;
    text-decoration: underline;
    &:hover{
      color: #CCB36E;
    }
  }
}
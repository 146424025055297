.account-container{
  & .order-table{
    & .ui.buttons{
      & > a {
        padding-left: 10px !important;
        padding-right: 10px !important;
        font-size: .85714286rem !important;
        border: 1px solid;
        &:nth-child(2){
          background: #CCB36E !important;
        }
      }

    }
  }
  #sylius-order{
    .sylius-product-name{
      font-weight: 400!important;
      color: black!important;
      text-transform: lowercase!important;
    }
  }
}
.golden{
  background: #CCB36E !important;
}

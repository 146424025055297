#footer {
  padding: 4em 0 1rem;
  margin: 0 auto;
  max-width: 1280px;

  .bottomFooter {
    font-size: 13px;
  }

  a:hover:not(.majCart) {
    color: $color-ocre;
  }

  .link-social {
    display: flex;
    gap: .5rem;
    margin-bottom: 10%;

    a {
      flex: 0 0 15%;

      img {
        width: 100%;
      }
    }
  }

  .ANOQHDF {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 13px;

    img {
      width: 15%;
    }
  }
}

.ui {
  &.footer.-override {
    background: none;
    @media only screen and (max-width: 767px) {
      & .ui.stackable.grid {
        text-align: center;
      }
    }

    & .ui.container {
      @media only screen and (min-width: 1200px) {
        width: 100%;
        margin: auto;
      }

      & .footer-grid.-override {
        & p {
          max-width: 70%;
          color: $color-black;
          font-size: 14px;
          @media screen and (max-width: 768px) {
            margin: 0 15%;
          }
        }

        & .four.wide.column.-override {
          box-shadow: none;
          -webkit-box-shadow: none;

          & > h4 {
            color: $color-black;
            font-weight: 400;
          }

          & > h4 + hr {
            width: 50%;
            margin: 10% 0 10% 0;
            @media screen and (max-width: 768px) {
              margin: 10% 25% 10% 25%;
            }
            border-color: $color-ocre;
          }

          & a:not(.majCart) {
            @media screen and (max-width: 768px) {

              width: max-content;
              display: block;
              margin: auto;
            }
            color: $color-black;
          }

          @media screen and (max-width: 768px) {
            & .link-social {
              & > a {
                display: initial;
              }
            }
          }

          & .are-you-pro {
            display: flex;
            flex-direction: row;
            align-content: center;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 15%;

            & > img {
              margin: 0 10px 10px 0;
            }

            & > p {
              display: flex;
              flex-direction: column;

              & > span {
                font-weight: 600;
              }
            }
          }

          & .cc.inverted {
            color: $color-black;
          }
        }
      }

      & > div:last-child {
        margin-top: 10rem;

        & p > a {
          text-decoration: underline;
          color: $color-black;
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  footer {
    .ANOQHDF {
      img {
        width: 50% !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .ui.footer.-override .ui.container > div:last-child {
    margin-top: 0 !important;
  }
  footer {
    .ANOQHDF {
      flex-direction: column;
      gap: 1rem !important;
    }

    .bottomFooter {
      flex-direction: column;
      gap: 1rem;
    }
  }
}

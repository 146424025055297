.ui {
  &.fluid {
    &.card.-override {
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      background: none;

      & .content.-override {
        font-family: $font-family-secondary-sans;
        & .header.-override{
          font-family: $font-family-secondary-sans;
          font-weight: 400;
          font-size: 18px;
          text-transform: initial;
        }
        &::after {
          display: none !important;
        }
      }

      & .sylius-product-name.-override {
        & > span {
          color: $color-ocre;
        }
      }

      & .sylius-product-price.-override {
        & span {
          //font-size: 1.3em;
          font-size: 14px;
        }
      }
    }
  }
  & .sylius-product-price.-override{
    & span{
      color: $color-ocre;
    }
  }
  //&.three.cards {
  //  & .fluid {
  //    &.card.-override {
  //      & .content.-override {
  //        display: flex;
  //        flex-direction: column;
  //        height: 100%;
  //        justify-content: space-between;
  //        padding: 5%;
  //
  //        &::after {
  //          display: none !important;
  //        }
  //      }
  //
  //      & .sylius-product-name.-override {
  //        margin-bottom: 2%;
  //
  //        & > span {
  //          color: $color-ocre;
  //        }
  //      }
  //
  //      & .sylius-product-price.-override {
  //        align-items: center;
  //        display: flex;
  //        justify-content: flex-start;
  //
  //        & span {
  //          font-size: 1.3em;
  //          margin-right: 5px;
  //        }
  //
  //        &.hasDiscount {
  //          position: relative;
  //
  //          & > del {
  //            position: absolute;
  //            top: -5px;
  //            left: 18%;
  //          }
  //        }
  //      }
  //    }
  //  }
  //}
}

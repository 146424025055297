@use "sass:math";

// Convert pixels to ems
@function em($size) {
  @return ($size * 1em);
}

// Convert pixels to rems
@function rem($size) {
  @return ($size / $rem-base) * 1rem;
}

// Hide only visually, but have it available for screenreaders
@mixin hide() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin hide-reset() {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@mixin line-clamp($lines: 2) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
}

@mixin supports-min-max {
  @supports (width: calc(max(15px, min(10vw, 10px)))) {
    @content;
  }
}

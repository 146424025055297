.serviceHotels{
  margin: 0 auto;
  padding: 5% 0;
  position: relative;
  &_title{
    h2{
      text-align: center;
      font-family: Nunito, sans;
      font-weight: 400;
      font-size: 21pt;
      margin-bottom: 3%;
      text-transform: uppercase;
      letter-spacing: 10px;
      color: black;
    }
  }
  &_grid{
    max-height: 800px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    &_item{
      grid-area: 2 / 1 / 6 / 4;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:nth-child(2){
        grid-area: 1 / 4 / 4 / 7;
      }
      &:nth-child(3){
        grid-area: 4 / 4 / 7 / 6;
      }
    }
  }
  &_description{
    margin: 5% auto;
    max-width: 850px;
    p{
      font-family: Montserrat, sans;
      font-style: normal;
      font-weight: 400;
      font-size: 13pt;
      line-height: 24px;
      color: #000;
      margin-bottom: 0;
    }
  }
  &:after{
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 57%;
    background: #EDECE9;
    z-index: -1;
    box-shadow: 0 40vw 0 40vw #EDECE9;
    -webkit-clip-path: inset(0 -40vw 0 -40vw);
    left: 0;
    top: 0;
    pointer-events: none;
  }
}

@media screen and (max-width: 768px) {
  .serviceHotels{
    &:after{
      height: 100%;
    }
  }
}
.sitemap {
  &-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    margin: 5% 0;
  }
  &-item{
    color: $color-black;
    text-decoration: none;
    &:hover{
      color: $color-ocre;
    }
    &-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: center;
      align-items: flex-start;
      margin: 5% 0;
      & > h2{
        font-size: 18pt;
      }
    }
  }
  &-title{
    font-family: 'Nunito', sans-serif;
    font-weight: 300 ;
    color: #CCB36E;
    text-transform: uppercase;
    font-size: 28exitpt;
    letter-spacing: 3px;
    line-height: 1.28571429em;
  }
}
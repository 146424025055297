.pro+.ui.container {
  margin-top: 9rem;
  background: white;
}

.ui.container {
  $p: &;
  & .anoq_pro_login_entete {
    text-align: center;
    margin-bottom: 2rem;
    padding: 30px;
    &-img {
      width: 100vw;
      transform: translateX(-50%);
      margin-left: 50%;
    }

    p {
      line-height: 30px;
      color: #000000;
      font-size: 21pt;
      font-weight: 400;
      font-family: Nunito, sans;
    }
  }
}

body.login .shadow + .ui.-override{
  background: url("../../img/login_bg.png");
  background-size: cover;
  padding-bottom: 200px;
}

.pageError{
  text-align: center;
  &_title{
    margin: 7rem 0;
    h1{
      color: #000000;
      font-size: 21pt;
      font-weight: 400;
      font-family: Nunito, sans;
    }
  }
  &_button{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0 auto;
    a{
      width: fit-content;
      height: fit-content !important;
      margin: 0 auto;
      font-family: Nunito, sans;
      font-style: normal;
      font-weight: 400;
      font-size: 13pt;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #000000;
      padding: 20px 100px !important;
      &:hover{
        background: #CCB36E;
      }
    }
  }
}

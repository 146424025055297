.button-header-burger {
  height: rem($spacing-024);
  position: relative;
  width: rem($spacing-024);

  --color-icon: #{$color-black};
  font-size: 0;
  margin-left: rem($spacing-012);

  transition: 0.3s all ease;

  .cart-length {
    background-color: $color-grey;
    border-radius: 50%;
    color: $color-white;
    display: block;
    font-size: rem($font-body-s);
    height: rem($spacing-016);
    position: absolute;
    right: 0;
    text-align: center;
    top: 42%;
    width: rem($spacing-016);

  }

  & .central {
    transition: .3s all ease;
    background-color: $color-black;
    display: var(--burger-central-display);
    height: rem($spacing-002);
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-1px);
    width: rem($spacing-024);
  }

  &:before,
  &:after {
    background-color: $color-black;
    content: '';
    display: block;
    height: rem($spacing-002);
    position: absolute;
    transition: all 0.3s ease;
    width: rem($spacing-024);
  }

  &:before {
    top: var(--burger-top-top);
    transform: var(--burger-rotation-top);
  }

  &:after {
    bottom: var(--burger-bottom-top);
    transform: var(--burger-rotation-bottom);
  }
}

.header-sticky-nav {
  --height-nav-mobile: 0;
  @media screen and (max-width: rem($layout-container-max)) {
    background-color: $color-white;
    display: block; // var(--sticky-menu-display);
    height: var(--height-nav-mobile);
    left: 0;
    margin: auto;
    overflow-y: auto;
    position: relative;
    right: 0;
    // top: var(--header-height);
    top: var(--header-height);
    transition: height 0.3s;
    width: 100%;
    padding-left: calc((100% - var(--grid-width)) / 2);
    padding-right: calc((100% - var(--grid-width)) / 2);

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .header-sticky-menu {
      display: var(--nav-display);
      list-style: none;
      margin: 0;
      padding: 0;
      transition: .3s all ease;
      a{
        color: white !important;
      }
      a[data-role="e-shop"] {
        svg {
          display: block;
        }
      }

      .nav-menu {
        overflow: unset;
        position: relative;
        top: unset;
        height: fit-content;
        min-height: 0px;
        max-height: 0px;
        overflow: hidden;
        transition: .3s all ease;
        opacity: 0;

        &.-open {
          opacity: 1;
          max-height: 100%;
          transition: .3s all ease;
        }
      }

      & .menu-n1 {
        display: block;
        list-style: none;
        padding: 1rem 0;
        width: 100%;

        &.-home {
          margin-bottom: 3rem;
          position: relative;

          & > a {
            margin-left: 4rem;
            font-size: 30px;
          }
        }

        .menu-n1-link {
          color: #5a5851;
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }

    .nav-layer {
      width: unset;
      padding: 0 0 0 10%;

      &-choose {
        border: none;
      }
    }
  }

  & .header-sticky-menu {
    & .menu-n1 {
      &.-home {
        margin-bottom: 3rem;
        position: relative;

        & > a {
          //margin-left: 4rem;
          font-size: 30px;
        }
      }
    }
  }

  & .close {
    cursor: pointer;

    &:before,
    &:after {
      background-color: $color-black;
      content: '';
      display: block;
      height: rem($spacing-002);
      position: absolute;
      transition: all 0.3s;
      width: rem($spacing-024);
    }

    &:before {
      top: var(--burger-top-top);
      transform: var(--burger-rotation-top);
    }

    &:after {
      bottom: var(--burger-bottom-top);
      transform: var(--burger-rotation-bottom);
    }
  }

  &.-open {
    --height-nav-mobile: calc(100vh - 140px);
    z-index: 100;
  }
}



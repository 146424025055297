div.faq-container{
  .faq-container_header{
    margin: 5% 0;
    text-align: center;
    &_title{
      h1{
        font-family: Nunito, sans;
        font-weight: 400;
        font-size: 21pt;
        margin-bottom: 3%;
        text-transform: uppercase;
        letter-spacing: 10px;
      }
    }
    &_subtitle{
      h2{
        letter-spacing: initial;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 0;
        font-family: Montserrat, sans;
        font-style: normal;
        line-height: 24px;
      }
    }
  }
  div.list{
    margin-bottom: 10%;
    ul{
      padding: 0;
      list-style-type: none;
      li{
        font-size: 1rem;
        border-bottom: 1px solid grey;
        margin: 0 0 1.2rem 0;
        cursor: pointer;
        h2{
          position: relative;
          padding-right: 50px;
          font-size: 18pt;
          text-transform: initial;
          letter-spacing: 3px;
        }
        h2:before{
          transition: all 0.3s;
          background-color: #000;
          content: '';
          display: block;
          position: absolute;
          width: 5px;
          height: 15px;
          right: 0;
          top: 0;
        }
        h2:after{
          transform: rotate(90deg);
          top: 0;background-color: #000;
          content: '';
          display: block;
          position: absolute;
          width: 5px;
          height: 15px;
          right: 0;
        }
        h2.open{
          color: #CCB36E;
        }
        h2.open:before{
          transform: rotate(90deg);
          transition: all 0.3s;
          background-color: #CCB36E;
        }
        h2.open:after{
          background-color: #CCB36E;
        }
        span.reponse{
          display: block;
          max-height: 0;
          min-height: 0px;
          font-weight: 200;
          margin-bottom: 1.2rem;
          overflow: hidden;
          transition: all 0.3s;
          padding: 0 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          color: #000;
          a{
            text-decoration: underline;
          }
        }
        h2.open + span.reponse{
          max-height: 500px;
          transition: all 0.3s;
          padding: 1.2rem 0 0 0;
        }
      }
      li:last-child{
        border: none;
      }
    }
  }
}

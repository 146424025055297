.cart {
  & .item {
    & strong > span {
      color: $color-ocre;
    }
  }

  &.button.-override {
    background: transparent;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-end;

    & > svg {
      font-weight: 100;
      width: 26px;
      margin-left: 15%;
    }
  }
}

.page-title {
  border-bottom: rem(1) solid $color-argil;
  color: $color-argil;
  display: flex;
  font-family: $font-family-sans;
  font-size: rem($font-body-3xl);
  font-weight: 500;
  justify-content: space-between;
  padding: rem($spacing-024) 0;
  text-transform: uppercase;
  width: 100%;

  &-filter {
    --color-icon: $color-argil;
  }

  &.-manuscrit {
    display: block;
    border-bottom: none;
    text-align: center;
    text-transform: unset;

    p {
      margin-bottom: 20px;
      font-family: $font-family-secondary-sans;
    }
  }

  @media #{$mq-width-desktop-medium} {
    padding: rem($spacing-024) rem($spacing-012);
  }

  .headerPoignees {
    text-align: left;

    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }

    h3 {
      font-family: $font-family-sans;
      font-size: 1.5rem;
      text-align: center;
    }

    p {
      a {
        text-decoration: underline;
      }
    }
  }
}

.ui.grid > div.continueShopping-container {
  position: absolute;
  top: calc(50% - 1rem);
  right: 0;
  padding: 0;
  @media only screen and (max-width: 767px) {
    top: -21px;
    right: -11px;
  }
  &.-no-float{
    position: initial;
  }
  & > a {
    text-decoration: none;
    color: $color-white;
    font-family: $font-family-secondary-sans;
  }
}

.ui.two.column.stackable.grid.cart-override {
  width: 100%;
  position: relative;
  & h1{
    color: $color-ocre;
    font-weight: 300;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

div.meanspayment {
  text-align: center;
  border: solid 1px $color-black;
  padding: 10% 0;

  div.icon {
    display: flex;
    width: 45%;
    margin: 0 auto;
    text-align: center;

    svg {
      width: 100%;
      height: 55px;
    }
  }

  p {
    svg {
      width: 10% !important;
    }
  }
}

div.checkout-modified {
  position: relative;
  width: 100%;
  margin: 15% 0;
  height: 45px;

  a.ui.huge.primary.fluid.labeled.icon.button {
    padding: .78571429em .78571429em .78571429em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: unset !important;
    padding-right: unset !important;
    border-radius: 0;
    position: relative;
    background-color: $color-black;
    font-family: $font-family-sans;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;

    svg {
      display: none;
    }
  }
}

div.five.wide.column {
  div.checkout {
    display: none;
  }
}

div.ui.segment.totals {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: transparent;

  div.oney-info {
    display: none;
  }

  h2.montantcart {
    border-bottom: unset;
    font-family: $font-family-secondary-sans;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    color: $color-black;
  }
}

table.totals {
  tbody {
    tr {
      td {
        border-top: unset !important;
        font-family: $font-family-secondary-sans;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: right;
        color: $color-black;
        &.right.aligned{

          color: $color-ocre;
          @media only screen and (max-width: 767px) {
            padding-right: 0!important;

          }
        }
        span {
          text-decoration: line-through;
        }
      }
    }
  }
}

a.majCart {
  text-transform: uppercase;
  font-family: $font-family-sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: .2em;
  text-align: center;
  color: $color-black;
  background-color: white;
  border-radius: 0;
  //border: 1px solid $color-black;
  padding: 18px 50px;
  &.-small{
    font-size: 12px;
    padding: 10px 10px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0;
  }
  &.-no-bold{
    font-weight: normal;
  }
  &.-border{
    border: 1px solid $color-black;
  }
  &.-invert{
    background-color: black;
    color: white;
  }
  &:hover{
    color: $color-white;
    background: $color-ocre;
  }
}

div.ui.segment.items {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: transparent;



  table#sylius-cart-items {
    tbody {
      tr {
        td {
          vertical-align: top;
          padding: 0;
          border: none;
          max-width: 500px;

          .ui.circular.button {
            border-radius: 0;
          }

          span.sylius-total {
            font-family: $font-family-secondary-sans;
            font-size: 18px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            color: $color-ocre;
          }

          div.bottomTab {
            justify-content: flex-end;
            display: flex;
            cursor: pointer;

            input {
              border-radius: 0;
              border: 0;
            }

            span {
              text-align: center;
              vertical-align: top;
              display: flex;
              padding: 0.7rem;
            }

            .sylius-quantity.ui.form {
              padding: 0;
            }

            form {
              vertical-align: top;
              display: inline-block;
              height: 0;

              button {
                width: 50px;
                height: 3.125rem;
                border: solid 1px $color-black;
                border-left: 0!important;
                & > svg{
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }

        td.right {
          vertical-align: top;
          @media only screen and (max-width: 767px) {
            padding-left: 0!important;
            padding-right: 0!important;
          }
        }
      }
    }
  }
}

.ui-input-number {
  align-content: center;
  align-items: center;
  border: solid 1px $color-black;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  height: rem(50);

  &.-small {
    height: rem(32);

    .ui-input-type-number-decreased,
    .ui-input-type-number-increased {
      width: rem(32);
    }
  }

  & > * {
    width: rem(50);
    min-width: calc(100% / 3);
    height: 100%;
  }

  & > input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    color: #{$color-argil};
    -moz-appearance: textfield;
    border: none;
    text-align: center;
    font-family: $font-family-secondary-sans;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
  }

  & > button {
    background-color: #d8d8d8;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    &:first-of-type {
      border-right: solid 1px $color-black;
    }

    &:last-of-type {
      border-left: solid 1px $color-black;
    }
  }

  .ui-input-type-number-decreased,
  .ui-input-type-number-increased {
    cursor: pointer;
  }

  //@media only screen and (max-width:)
}


div.bottom-cart {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  & > a {
    text-decoration: none;
    color: $color-black;
    font-family: $font-family-secondary-sans;
    font-size: 16px;
    &:hover{
      color: $color-ocre;
    }
  }
}

div.totalsubpromo {
  border-top: solid 1px #979797;

  table.ui.very.basic.table {
    tr {
      td {
        padding: 0;
        font-family: $font-family-secondary-sans;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: $color-black;
        &.right.aligned{
          @media only screen and (max-width: 767px) {
            padding-right: 0!important;

          }
          color: $color-ocre;
        }
      }
    }
  }
}

div.subTotalDiv {
  div#sylius-coupon {
    margin: 5% 0;

    input[type=text] {
      border-radius: 0;
    }

    button[type=submit] {
      border-radius: 0;
    }

    div.ui.input {
      width: 100%;

      button#sylius-save {
        padding: 3% 7% !important;
        text-transform: uppercase;
        font-family: $font-family-secondary-sans;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        background-color: $color-black;
      }
    }
  }
}

table#sylius-cart-items {
  tr {
    td {
      div.sylius-product-name {
        font-family: $font-family-secondary-sans;
        font-size: 18px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: $color-black;
        max-width: 500px;
      }

      span.sylius-product-variant-code {
        font-family: $font-family-secondary-sans;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.08;
        letter-spacing: normal;
        color: $color-black;
        margin: 2% 0;
      }

      div.ui.header {
        img {
          width: 64px;
          height: 64px;
          vertical-align: top;
        }
      }

      input#sylius_cart_items_0_quantity {
        border-radius: 0;
        background-color: transparent;
      }

      div.bottomTab {
        .ui.button {
          font-size: 0.73rem;
        }

        margin-top: 25%;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    td.right.aligned {
      font-family: $font-family-secondary-sans;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: right;
      color: $color-black;
    }
  }
}

div.ui.stackable.grid.summary {
  margin-bottom: 5%;
}

table#sylius-cart-items tr td div.bottomTab {
  margin: 15% 0 10%;
}

@media screen and (max-width: 768px) {
  //div.ui.segment.items table#sylius-cart-items tbody tr td{
  //  margin-left: -10%;
  //}
  //table#sylius-cart-items tr td div.ui.header img{
  //  width: 70%;
  //  height: auto;
  //}
  table#sylius-cart-items tr td div.ui.header img {
    width: 100%;
    height: auto;
    margin-bottom: 10%;
    max-width: 149px;
  }
  .ui.table [class*="single line"], .ui.table[class*="single line"] {
    white-space: unset;
  }
  .ui.header > .image + .content, .ui.header > img + .content {
    display: block;
  }
  div.bottom-cart {
    display: block;

    button {
      width: 110% !important;
      float: unset !important;
      margin-top: 5% !important;
    }
  }
  div.checkout-modified {
    position: relative;
  }
  table.totals {
    tr {
      border-bottom: none !important;

    }
  }
  .ui.table:not(.unstackable) tr {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  table#sylius-cart-items {
    div.bottomTab {
      margin-top: 15% !important;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1180px) {
  .ui.column.grid > [class*="eleven wide"].column, .ui.grid > .column.row > [class*="eleven wide"].column, .ui.grid > .row > [class*="eleven wide"].column, .ui.grid > [class*="eleven wide"].column {
    width: auto !important;
  }
  .ui.column.grid > [class*="five wide"].column, .ui.grid > .column.row > [class*="five wide"].column, .ui.grid > .row > [class*="five wide"].column, .ui.grid > [class*="five wide"].column {
    width: auto !important;
  }
  div.ui.stackable.grid.summary {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1170px) {
  table#sylius-cart-items tr td div.sylius-product-name {
    font-size: 11px;
  }
}

@media screen and (max-width: 1000px) {
  @media screen and (max-width: 1000px) {
    .ui.column.grid > [class*="eleven wide"].column, .ui.grid > .column.row > [class*="eleven wide"].column, .ui.grid > .row > [class*="eleven wide"].column, .ui.grid > [class*="eleven wide"].column {
      width: 100% !important;
      margin: 0 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      padding: 1rem 1rem !important;
    }
    .ui.column.grid > [class*="five wide"].column, .ui.grid > .column.row > [class*="five wide"].column, .ui.grid > .row > [class*="five wide"].column, .ui.grid > [class*="five wide"].column {
      width: 100% !important;
      margin: 0 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      padding: 1rem 1rem !important;
    }
  }
}
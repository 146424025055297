.ui{
  &.breadcrumb{
    & .section{
      color: black;
      &:hover{
        color: $color-ocre;
      }
      &.-override{
        color: $color-ocre;
      }
    }
  }
}
.ui {
  &.three.column.grid {
    & .column.menu-override {
      gap: 1.5rem;
      display: flex;
      #sylius-account-button,#sylius-cart-button{
        padding: 0;
      }
    }
  }

  & .menu.-override {
    border: none;
    background: none;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    visibility: visible;
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;

    & .item {
      border: none;
      background: none;
      max-width: 160px;
      text-align: center;
      text-transform: uppercase;
      font-family: 'Nunito', sans-serif;
      line-height: 14px;
      &::before {
        background: none;
      }
      &:hover{
        background: none;
        color: $color-ocre;
        cursor: pointer;
      }
    }
    &::after{
      content: none;
    }

  }
  & .header-sticky-nav {
    position: fixed;
    top: 8%;
    height: 100vh;
    background: #000000;
    right: -133vw;
    width: 33vw;
    z-index: 100;
    transition: right 0.5s;
    padding: 1rem 0 0 2rem;
    & > ul{
      list-style-type: none;
      & > hr{
        width: 50%;
        margin-left: 0;
      }
      & > li{
        margin: 10px 0;
        & > a{
          text-decoration: none;
          color: $color-white;
          font-size: 20px;
          font-family: $font-family-secondary-sans;
          font-weight: 400;
          &:hover{
            color: $color-ocre;
          }
        }
      }
    }
    &.-open{
      right: 0;
    }
  }

  & .button-header-burger{
    visibility: hidden;
  }
  & .button-header-account,
  & .button-header-search{
    cursor: pointer;
  }
  & .button-header-search:hover{
    & > svg > line{
      stroke: $color-ocre;
    }
    & > svg > g > path{
      fill: $color-ocre;
    }
  }
  & .button-header-account:hover{
    & > svg > circle,
    & > svg > path{
      stroke: $color-ocre;
    }
    & > svg > g > path{
      fill: $color-ocre;
    }
    & > svg > g > polyline{
      stroke: $color-white;
    }
  }
  &.circular.cart.button.-override:hover{
    & > svg > path{
      stroke: $color-ocre;
    }
    & > svg > text,
    & > svg > g > path{
      fill: $color-ocre;
    }
  }
  .scrolling-active {
    & .menu.-override {
      visibility: hidden;
    }
    //& .header-sticky-nav,
    & .button-header-burger {
      visibility: visible;
    }
  }

}

@media screen and ($mq-width-mobile-medium) {
  .ui {
    & .menu.-override {
      display: none;
    }

  }
}

@media screen and ($mq-width-desktop-medium) {
  .ui {
    & .menu.-override {
      display: flex;
    }
  }
}

@media screen and (max-width: 768px) {
  .ui .button-header-burger{
    visibility: visible;
  }
  .ui .header-sticky-nav{
    transition: .5s all ease;
    width: 100%;
    top: 16%;
    right: -250vw;
  }
}

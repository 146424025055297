.editPR{
  cursor: pointer;
}
.five.wide.column, .ui.stackable.grid{
  .ui.segment{
    padding: 14px;
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .sylius-product-name{
    color: $color-black;
    font-family: 'Nunito', sans-serif;
    & > span {
      color: $color-ocre;
    }
  }
}
.address-book-select{

  & .text,.item{
    letter-spacing: normal!important;
  }
}

.ui.large.flowing.cart.popup.top.right.transition{
  .item{
    font-weight: 500;
    font-size: 14px;
    strong{
      font-weight: 500;
      font-size: 14px;
      & + span{
        color: $color-ocre;
      }
    }
  }
}

#sylius-coupon{
  #sylius_cart_promotionCoupon{
    border-radius: 0;
    &:focus {
      border-color: $color-black;
    }
  }
}

button.button.sylius-cart-remove-button{
  background-color: transparent !important;
  border: 1px solid black !important;
  padding: 10px 15px !important;
  i{
    display: block;
    color: black;
  }
}
#sylius-cart-clear{
  color: black !important;
  -webkit-box-shadow: 0 0 0 1px black inset!important;
  box-shadow: 0 0 0 1px black inset!important;
}

.ui.header>.icon+.content{
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

#sylius-cart-items{
  tbody{
    tr{
      td.single.line{
        white-space: unset !important;
        .ui.header{
          display: flex;
          .content{
            width: unset;
          }
        }
      }
    }
  }
}

.five.wide.column{
  h2.ui.dividing.header{
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.ui.very.basic.table{
  .ui.large.header{
    td{
      font-family: 'Nunito', sans-serif;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
}

.ui.four.steps{
  a.active.step{
    i{
      color: #CCB36E !important;
    }
    .title{
      color: #CCB36E !important;
    }
  }
}

#sylius-billing-address, #sylius-checkout-subtotal{
  & div:first-child.ui.dropdown {
    text-align: center;
  }
  .header,.ui.large.header{
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500 !important;
    text-transform: uppercase;
    strong{
      font-family: 'Nunito', sans-serif !important;
      font-weight: 500 !important;
      text-transform: uppercase;
    }
  }
}

#sylius-order{
  .sylius-product-name{
    font-family: 'Nunito', sans-serif !important;
    font-weight: 500 !important;
    color: #CCB36E !important;
    text-transform: uppercase;
  }
}

.returnBoutique{
  display: flex !important;
  align-items: center;
  a{
    text-decoration: none;
    color: black;
    &:hover{
      color: #CCB36E;
    }
  }
}

.ui.unmargined.segments{
  box-shadow: none !important;
}
.ui.segment.noBackgroundCheckout, .ui.celled.table.noBackgroundCheckout{
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none !important;
  border-radius: 0;
  background-color: transparent;
  .ui.dividing.header{
    border: unset;
    box-shadow: unset;
    -webkit-box-shadow: none ;
  }
  label{
    font-weight: 400;
    &:hover{
      color: #CCB36E;
    }
  }
  .ui.segments:not(.horizontal)>.segment:only-child{
    border: none !important;
  }
}

.ui.four.steps{
  a{
    gap: 5px;
    svg{
      height: 75px;
    }
    &.disabled{
      svg.icon1{
        path, ellipse{
          stroke: rgba(40,40,40,.3);
        }
      }
      svg.icon2, svg.icon3{
        fill: rgba(40,40,40,.3);
      }
    }
    &.active{
      svg.icon1{
        path, ellipse{
          stroke: #CCB36E;
        }
      }
      svg.icon2, svg.icon3{
        fill: #CCB36E;
      }
      svg.icon4{
        polyline{
          stroke: #CCB36E;
        }
      }
    }
  }
  .disabled.step{
    svg.icon4{
      polyline{
        stroke: rgba(40,40,40,.3);
      }
    }
  }
  .active.step{
    svg.icon4{
      polyline{
        stroke: #CCB36E;
      }
    }
  }
}

.noBackgroundCheckout{
  & .ui.items>.item {
    & .extra {
      width: 12%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
    & .content > .description > p {
      font-size: 0.9em;
    }
  }

}
.loader{
  text-align: center;
  display: inline-flex;
  justify-content: center;
  background-color: white;
  align-items: center;
}

.loader h2{
  margin-left: 15px;
  font-family: Poppins, sans-serif;
  color: #5a5851;
  margin-top: 0;
  font-size: 15px;
  letter-spacing: inherit;
  text-transform: initial;
}
.loading-complete-cart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading-complete-cart div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #5a5851;
  border-radius: 50%;
  animation: loading-complete-cart 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5a5851 transparent transparent transparent;
}
.loading-complete-cart div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-complete-cart div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-complete-cart div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-complete-cart {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

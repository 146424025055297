.page-top-image {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 100vw;

  & + .breadcrumb-top {
    .breadcrumb-top-list {
      border: none;
    }
  }

  .ui-wrapper {
    width: 100%;
    max-width: unset;
  }

  &-container {
    --slide-height: 580px;
    height: var(--slide-height);
    overflow: hidden;
    position: relative;

    left: 50%;
    transform: translateX(-50%);
    width: 100vw;

    .glide__slide {
      height: var(--slide-height);

      img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .buttons {
      bottom: 0;
      font-size: 0;
      position: absolute;
      right: 0;
      z-index: 1;
    }

    h2, h3{
      font-family: $font-family-secondary-sans;
      font-size: rem($font-display-xs);
      left: rem($spacing-012);
      position: absolute;
      pointer-events: none;
      bottom: rem(50);
      text-align: left;

      &.-variant-1 {
        color: $color-argil;
      }

      &.-variant-2 {
        color: $color-white;
      }
    }
    h3{
      text-transform: unset;
      letter-spacing: initial;
    }
  }


  .container-image-home{
    //max-width: 1280px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    gap: 1rem;
  }
  .image-home-legend {
    top: 20%;
    max-width: 500px;
    margin-left: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    gap: 1rem;
    h2,h3 {
      position: static;
      margin-top: 0;
      &.hidden{
        visibility: hidden;
      }
      br {
        display: none;
      }
    }
    h2 {
      font-size: 26px;
      line-height: 1.3;
      font-weight: 400;
      letter-spacing: normal;
    }
    h3 {
      font-family: $font-family-secondary-sans;
      font-weight: 400;
      margin-bottom: 5rem;
      font-size: 20px;

    }
    a {
      cursor: pointer;
      font-size: 13pt;
      padding: 2rem;
      font-weight: 300;
      font-family: $font-family-sans;
      letter-spacing: 3px;
      width: fit-content;
      text-transform: uppercase;
      &:hover{
        background: #CCB36E;
      }
    }

    &_title{
      //border: 1px solid white;
      padding: 15px 35px;
      font-weight: 400;
      h3{
        margin-bottom: unset;
      }
    }
  }

  @media #{$mq-width-desktop-medium} {
    // h1,h2 {
    //   left: 50%;
    // }
  }

  @media #{$mq-width-tablet-large} {
    .image-home-legend {
      // left: 42.5vw;
      bottom: rem(55);
      transform: unset;
      // max-width: 50vw;
      // padding: 1em 3em 1em 2em;
    }
  }

  @media #{$mq-width-tablet-xlarge} {
    .image-home-legend {
      left: 5%;
      padding: 0em 3em 1em 0;
      br {
        display: inline;
      }
    }
  }




  @media screen and (max-width: 465px) {
    // h1,h2 {
    //   font-size: 2.4rem;
    // }
    .image-home-legend {
      h2 {
        font-size: 1.6rem;
      }
      h7 {
        font-size: .6rem;
      }
    }

  }
}


@media #{$mq-width-tablet-xlarge} {
  .homepage-template {
    .homepage-title {
      .homepage-title-picture {
        display: inline-block !important;
      }
    }
  }
}

@media #{$mq-width-tablet-large} {
  .homepage-template {
    .page-top-image {
      .image-home-legend {
        left: 42.5vw !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .page-top-image{
    .image-home-legend{
      width: 90%;
      max-width: unset;
      &.button-center{
        width: 100%;
        margin: 30% 0;
        padding: 0;
        justify-content: flex-end;
        align-items: center;
      }
      a{
        font-size: 8pt;
      }
    }
  }
}

* {
  font-family: $font-family-secondary-sans;
}

.ltn {
  letter-spacing: normal;
}

.ui.popup{overflow:auto;}

body{
  font-size: $rem-base+px;

  &.-active-shadow {
    overflow: hidden;
  }

  &.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }
}

div.shadow {
  display: none;
  position: absolute;
  background: #00000080;
  width: 100vw;
  height: 100vw;
  top: 0;
  left: 0;
  z-index: 5;

  &.-active {
    display: block;
  }
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  line-height: inherit;
  padding: 0;
  text-align: inherit;
}

header {
  height: 11rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: .5s all ease;

  &.pro {
    height: 6rem;
  }
}

h1 {
  color: #000000;
  font-size: 21pt;
  font-weight: 400;
  font-family: Nunito, sans;
}

h2 {
  font-family: Nunito, sans;
  font-weight: 400;
  font-size: 21pt;
  margin-bottom: 3%;
  text-transform: uppercase;
  letter-spacing: 10px;
}

p {
  font-family: Montserrat, sans;
  font-style: normal;
  font-weight: 400;
  font-size: 13pt;
  line-height: 24px;
  color: #000;
}

a {
  text-decoration: none;
  color: black;
}

.ui {
  & .ui.toggle.checkbox input:checked ~ label:before {
    background-color: $color-ocre !important;
  }

  & .ui.breadcrumb:first-child {
    margin-top: 3em;
    margin-left: 1em;
    margin-bottom: 1em;
  }

  &.basic.segment.-header-override {
    position: fixed;
    width: 100%;
    padding: 1% 5%;
    z-index: 11;
    top: 50px;

    & > .grid {
      justify-content: flex-end;
    }

    @media screen and (max-width: 768px) {
      top: 80px;
      margin: 0;
      & > .grid {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        padding: 0 0;

        & h1 svg {
          width: 136px;
        }
      }
    }
    @media screen and (max-width: 420px) {
      top: 90px;
    }
    margin-top: 0;
    margin-bottom: 130px;
    background: #f9fAfb;
    transition: top 0.3s ease 0s;

  }

  & .scrolling-active {
    height: 0rem;
    transition: .3s all ease;

    & .basic.segment.-header-override {
      top: 0;
    }
  }

  &.container.-override:has(div.homepage) {
    width: 100%;
  }

  &.labeled.button.-override,
  &.primary.button.-override {
    background: $color-ocre;
    font-family: $font-family-secondary-sans;
  }

  &.header {
    font-family: $font-family-secondary-sans;
  }

}

hr.footer-separator {
  margin: 6em 17%;
  //max-width: 1280px;
  width: 1127px;
  margin-left: auto !important;
  margin-right: auto !important;
  //padding: 0 5%;
}

#sylius-account-button, #sylius-cart-button, #sylius-search-button {
  background-color: transparent !important;
  padding: 0 !important;
}

#sylius-search-button {
  & > input {
    animation: unreveal 1s backwards;
    position: absolute;
    left: 0;
    width: 50%;
    border: none;
    background: transparent;
    bottom: 30%;
    border-bottom: 1px solid;
    @media screen and (max-width: 768px) {
      left: -80%;
      padding: 20px 0;
      bottom: 0;
      width: 80%;
      background: #f9fAfb;
      border-bottom: none;
    }

    &.open {
      display: inline-block;
      animation: reveal 1s forwards;
      border: 1px solid black;
      border-radius: 0;
      padding-left: 5px;
    }

    &.hidden {
      visibility: collapse;
    }
  }
}

.ui.horizontal.section.divider.header {
  font-family: 'Nunito', sans-serif;
}

.ui.top.right.popup {
  inset: 70px 63.4998px auto auto !important;
  display: block !important;

  &:before {
    top: -0.30714286em;
    right: 1em;
    bottom: auto;
    left: auto;
    margin-left: 0;
    -webkit-box-shadow: -1px -1px 0 0 #bababc;
    box-shadow: -1px -1px 0 0 #bababc;
  }
}

.imageError {
  width: 20%;
  margin: 5% auto;
}

.menu.hidden {
  display: none !important;
}

.item.sylius-available-locale.selected {
  color: #CCB36E !important;
}

.item.sylius-available-locale {
  padding: 0.9em 5px !important;
}

.cgv-template {
  margin-top: 10%;

  & h1 {
    font-family: $font-family-sans;
    font-weight: 400;
    font-size: 21pt;
    margin-bottom: 3%;
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
  }
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes unreveal {
  0% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 100%);
    overflow: hidden;
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

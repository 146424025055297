.checkout-popup{
  opacity: 0;
  pointer-events: none;
  transition: .3s all ease;
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  z-index: 1000;
  background: rgba(0,0,0,0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  &-container{
    &.ui.container {
      position: relative;
      background: #f9fAfb;
      padding: 35px;
      width: 640px;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }
    .close{
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 5px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      &.fake{
        position: initial!important;
        width: auto;
        height: auto;
        color: inherit;
      }
      img{
        width: 100%;
        height: 100%;
      }
    }
    &-form{
      width: 100%;
      & .column:nth-child(1){
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: center;
        margin: 0 0 20px 0;
      }
      & .column:nth-child(2){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        align-items: end;
        & a:last-child{
          grid-area: 2 / 1 / 3 / 3;
          text-align: center;
          border: 1px solid;
          &:hover{
            text-decoration: underline;
          }
        }
      }
      h2{
        color: #CCB36E !important;
        font-family: Nunito, sans !important;
        font-style: normal;
        font-weight: 400;
        font-size: 25pt;
        line-height: 35px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
      }
      form.ui.form{
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        & .checkout-input input{
          width: 260px;
          background: transparent !important;
          border: none;
          border-bottom: 1px solid $color-black;
          border-radius: 0;
          &::placeholder{
            color: black;
            font-family: $font-family-secondary-sans;
            font-style: italic;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
          }
        }

        & div.checkout-button > button {
          font-family: $font-family-sans;
          background: $color-black;
          color: $color-white;
          //border: 1px solid white;
          padding: 11px 17px !important;
          border-radius: 0;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }
    &-picture{
      flex: 0 0 50%;
      display: flex;
      position: relative;
      justify-content: space-between;
      &-item{
        flex: 0 0 48%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &.-open{
    transition: .3s all ease;
    opacity: 1;
    pointer-events: auto;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-popup {
    &-container-form {
      width: 100%;
      & .column{
        width: 100%;
        & form{
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .checkout-popup{
    &-container{
      justify-content: center;
      &-picture{
        display: none;
      }
    }
  }
}

.stateOfMind{
  margin: 5% auto 0;
  position: relative;
  &_image{
    display: flex;
    align-items: end;
    gap: 3rem;
    &_first{
      width: 30%;
      img{
        width: 100%;
      }
    }
    &_second{
      width: 50%;
      img{
        width: 100%;
      }
    }
  }
  &_text{
    display: flex;
    align-items: end;
    position: relative;
    margin-top: -20%;
    padding-bottom: 10%;
    gap: 3rem;
    &_left{
      &_title{
        h2{
          font-family: Nunito, sans;
          font-weight: 400;
          font-size: 21pt;
          margin-bottom: 3%;
          text-transform: uppercase;
          letter-spacing: 10px;
        }
      }
      &_subtitle{
        p{
          font-weight: 400;
          font-size: 18pt;
          line-height: 23pt;
          margin-bottom: 0;
          color: #889380;
        }
      }
    }
    &_right{
      background-color: #889380;
      padding: 90px 80px;
      position: relative;

      p{
        font-family: Montserrat, sans;
        font-style: normal;
        font-weight: 400;
        font-size: 13pt;
        line-height: 24px;
        color: white;
        margin-bottom: 0;
      }
    }
    &:after{
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background: #EDECE9;
      bottom: -20px;
      z-index: -1;
      box-shadow: 0 40vw 0 40vw #EDECE9;
      -webkit-clip-path: inset(0 -40vw 0 -40vw);
      left: 0;
      bottom: 0;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .stateOfMind{
    &_image{
      flex-direction: column;
      gap: 1rem;
      &_first, &_second{
        width: 100%;
      }
    }
    &_text{
      flex-direction: column;
      margin-top: 0;
      padding-top: 10%;
      &_right{
        padding: 50px 30px;
      }
    }
  }
}
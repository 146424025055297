.testimony{

  margin: 5% auto;
  max-width: 1280px;
  padding: 0 5%;
  //width: 90%;
  //margin: 5% auto;
  &_header{
    text-align: center;
    margin-bottom: 3%;
    h2{
      font-family: Nunito, sans;
      font-weight: 400;
      font-size: 21pt;
      margin-bottom: 3%;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 10px;
    }
    p{
      font-size: 15pt;
      font-family: Montserrat, sans;
      font-weight: 400;
      margin-bottom: 3%;
      line-height: 18pt;
    }
  }
  &_container{
    &_list{
      &_item{
        &_date{
          font-size: 12pt;
          color: #CCB36E;
          line-height: 14pt;
        }
        &_name{
          font-size: 18px;
          line-height: 24pt;
          font-family: Montserrat, sans;
          margin-bottom: 5%;
        }
        &_temoignage{
          font-size: 14px;
          font-family: Montserrat, sans;
        }
      }
    }
    .glide__bullets__testimony{
      display: flex;
      justify-content: center;
      margin: 5% 0;
      button{
        width: 10px;
        height: 10px;
        border: 1px solid black;
        &.glide__bullet--active{
          background-color: black;
        }
      }
    }
  }
}
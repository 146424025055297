.popup-sucess{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #0000008c;
  z-index: 100;
  & > h3 {
    background: $color-ocre;
    color: $color-white;
    font-family: Montserrat, sans;
    top: calc(50% - 30px);
    position: absolute;
    font-weight: 400;
    left: calc(50% - 160px);
    padding: 20px;
  }
}
.ui.product{
  font-family: Montserrat, sans;
  font-size: 15pt;
  p{
    font-family: Montserrat, sans;
    font-size: 13pt;
  }
  h1{
    font-family: Nunito, sans-serif;
    text-transform: uppercase;
    font-size: 25pt;
  }
  & .ui.monster.header{
    color: $color-black;
    font-size: 21pt;
    font-weight: 400;
    font-family: Nunito, sans;
    text-transform: initial;
    & > span{
      color: $color-ocre;
    }
  }
  & .ui.huge.header{
    font-size: 1.7rem;
    font-weight: 400;
  }
}
.ui.star.rating i.active.icon{
  color: #CCB36E!important;
}
.ui.bottom.attached.tab.segment.active{
  background-color: transparent;
  border: none;
  font-size: 13pt;
}
.ui.top.attached.large.tabular.menu{
  .item{
    font-family: 'Nunito', sans-serif;
    background-color: transparent;
    border: none;
    font-size: 15pt;
  }
}
#sylius-product-selecting-variant{
  background: transparent;
  border: 0;
  box-shadow: unset;
  label{
    font-family: Montserrat, sans;
    font-size: 15pt;
    font-weight: 400;
  }
  button[type=submit]{
    width: 100%;
  }
}
.button{
  background-color: black !important;
  color: white !important;
  padding: 18px 50px !important;
  border-radius: 0 !important;
  font-size: 12pt !important;
  text-transform: uppercase !important;
  font-family: Nunito, sans !important;
  line-height: 20px !important;
  letter-spacing: .2em !important;
  font-weight: 400 !important;
  i{
    display: none;
  }
  &:hover{
    background: #CCB36E !important;
  }
}
.ui.basic.segment, .ui.segment{
  padding-left: 0;
  padding-right: 0;
}
.ui.info.message, .ui.messagen .ui.positive.message,.ui.icon.positive.message.sylius-flash-message{
  border-radius: 0;
  color: #CCB36E;
  background-color: rgba(204, 179, 110, .1);
  -webkit-box-shadow: 0 0 0 1px #CCB36E inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #CCB36E inset, 0 0 0 0 transparent;
  div.header, p{
    color: #CCB36E;
  }
}
.ui.section.divider{
  font-size: 13pt;
}
.ui.card>.content>a.header:hover, .ui.cards>.card>.content>a.header:hover{
  color: #CCB36E;
}
.ui.labeled.icon.button, .ui.labeled.icon.buttons .button{
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.ui[class*="right floated"].button{
  float: unset;
  margin-left: 0;
}
.twelve.wide.right.aligned.column{
  .ui.header{
    color: black;
    font-weight: 400;
  }
}
input[type=number]{
  border-radius: 0 !important;
}
.required.field{
  label{
    font-family: Montserrat, sans;
    font-size: 13pt;
    &:after{
      color: #CCB36E !important;
    }
  }
}
.ui.inverted.button{
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  &:hover{
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
  }
}
.ui.fluid.image{
  max-height: 550px !important;
  max-width: 550px !important;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  & img{
    height: unset;
    width: 100%;
    transition: 1s all;
    &:after{
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  &:hover{
    img{
      transform: scale(1.1);
      transition: 1s all;
    }
  }
}
#imageDetails{
  overflow: hidden;
  img{
    height: 130px !important;
    object-fit: cover !important;
    transition: 1s all;
  }
  &:hover{
    img{
      transform: scale(1.1);
      transition: 1s all;
    }
  }
}
.ui.small.image,
.ui.small.images .image,
.ui.small.images img,
.ui.small.images svg{
  width: 130px;
}

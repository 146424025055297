form{
  .errorCaptcha{
    min-height: 0px;
    max-height: 0px;
    transition: .3s all ease;
    opacity: 0;
    color: #db2828;
    &.-active{
      min-height: 0px;
      max-height: 100px;
      transition: .3s all ease;
      opacity: 1;
    }
  }
  .form-hidden{
    display: none;
  }
}

.titreSoustitre{
  text-align: center;
  margin: 5% auto;
  &_title{
    h1{
      font-family: Nunito, sans;
      font-weight: 400;
      font-size: 21pt;
      margin-bottom: 3%;
      text-transform: uppercase;
      letter-spacing: 10px;
    }
  }
  &_subtitle{
    p{
      font-family: Montserrat, sans;
      font-style: normal;
      font-weight: 400;
      font-size: 13pt;
      line-height: 24px;
      color: #000;
      margin-bottom: 0;
    }
  }
}
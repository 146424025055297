#menu{
  transition: .5s all ease;
  @media screen and (max-width: 768px) {
    position: fixed;
    width: 100%;
  }
  &.scrolling-active{
    transform: translateY(-100%);
    transition: .5s all ease;
  }
}

.menu-item {
  color: white;
  margin: 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p{
    color: white;
    @media screen and (max-width: 768px) {
      font-size: 9pt;
    }
  }
  & + div {
    .menu-item-locale{
      display: flex;
    }
    position: absolute;
    right: 5%;
    top: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    & .menu-item-pro{
      color: $color-white;
      background: $color-ocre;
      padding: 0 5px;
      margin-right: 20px;
      font-family: Nunito, sans;
      text-align: center;
      &:hover{
        color: black;
        cursor: pointer;
      }
    }
    & .item.selected:before{
      content: none;
    }
    & .item.selected:after:not(:last-child){
      position: absolute;
      content: '';
      top: 20%;
      right: 25%;
      height: 60%;
      width: 52%;
      background: #CCB36E;
      z-index: -1;
      border-radius: 14px;
    }
  }
}

.-logo-override img.ui.small.image {
  width: 165px;
  margin: auto;
}

.ui.button.-override {
  display: inline-block;
  background: none;
  vertical-align: middle;
}

.menu-override {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
}


@media screen and (max-width: 768px) {
  .menu-item + div{
    position: relative;
    left: 0;
    width: 100%;
    justify-content: space-between;
    padding: 0 40px;
    .menu-item-pro{
      padding: 5px;
    }

    a{
      flex: 0 0 30% !important;

    }
  }
}
@media screen and (max-width: 420px) {
  .menu-item + div {
    padding: 0 15px;
  }
}

// Do not edit directly
// Generated on Mon, 26 Jul 2021 11:55:24 GMT
$rem-base: 16;
$z-index-050: 50;
$spacing-096: 96;
$spacing-080: 80;
$spacing-064: 64;
$spacing-056: 56;
$spacing-050: 50;
$spacing-048: 48;
$spacing-043: 43;
$spacing-042: 42;
$spacing-040: 40;
$spacing-032: 32;
$spacing-027: 27;
$spacing-024: 24;
$spacing-020: 20;
$spacing-016: 16;
$spacing-012: 12;
$spacing-010: 10;
$spacing-008: 8;
$spacing-004: 4;
$spacing-002: 2;
$spacing-128: 128;
$shadow-032-dp: 0 32px 48px 0 rgba(52, 52, 52, 0.08);
$shadow-024-dp: 0 24px 32px 0 rgba(52, 52, 52, 0.08);
$shadow-016-dp: 0 16px 24px 0 rgba(52, 52, 52, 0.06);
$shadow-008-dp: 0 8px 16px 0 rgba(52, 52, 52, 0.06);
$shadow-004-dp: 0 4px 8px 0 rgba(52, 52, 52, 0.04);
$shadow-002-dp: 0 2px 4px 0 rgba(52, 52, 52, 0.04);
$radius-50: 50%;
$radius-032: 32;
$radius-024: 24;
$radius-020: 20;
$radius-016: 16;
$radius-012: 12;
$radius-008: 8;
$radius-004: 4;
$radius-002: 2;
$mq-width-desktop-max: 'min-width: 120em';
$mq-width-desktop-xlarge: 'min-width: 100em';
$mq-width-desktop-large: 'min-width: 90em';
$mq-width-desktop-medium: 'min-width: 73.125em';
$mq-width-tablet-xlarge: 'min-width: 64em';
$mq-width-tablet-large: 'min-width: 45em';
$mq-width-tablet-medium: 'min-width: 37.5em';
$mq-width-mobile-xlarge: 'min-width: 26.25em';
$mq-width-mobile-large: 'min-width: 23.4375em';
$mq-width-mobile-medium: 'min-width: 22.5em';
$layout-container-max: 1170;
$grid-desktop-xlarge-columns: 12;
$grid-desktop-xlarge-margin: 5.75vw;
$grid-desktop-large-columns: 12;
$grid-desktop-large-margin: 5.8333333333vw;
$grid-desktop-medium-columns: 12;
$grid-desktop-medium-margin: 5.46875vw;
$grid-tablet-xlarge-columns: 12;
$grid-tablet-xlarge-margin: 5.46875vw;
$grid-tablet-large-columns: 12;
$grid-tablet-medium-columns: 8;
$grid-mobile-medium-columns: 4;
$grid-root-columns: 4;
$gradient-to-bottom-0-060: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.6) 0%,
                rgba(0, 0, 0, 0) 50%
);
$gradient-to-bottom-0-050: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.5) 0%,
                rgba(0, 0, 0, 0) 50%
);
$gradient-to-bottom-0-040: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.4) 0%,
                rgba(0, 0, 0, 0) 50%
);
$gradient-to-bottom-0-030: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.3) 0%,
                rgba(0, 0, 0, 0) 50%
);
$gradient-to-bottom-0-020: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0) 50%
);
$gradient-to-bottom-0-010: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.1) 0%,
                rgba(0, 0, 0, 0) 50%
);
$gradient-to-top-0-060: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.6) 100%
);
$gradient-to-top-0-050: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.5) 100%
);
$gradient-to-top-0-040: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.4) 100%
);
$gradient-to-top-0-030: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.3) 100%
);
$gradient-to-top-0-020: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.2) 100%
);
$gradient-to-top-0-010: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.1) 100%
);
$gradient-mask-right: linear-gradient(
                90deg,
                #000000 0%,
                #000000 87%,
                rgba(0, 0, 0, 0.5) 100%
);
$gradient-mask-left: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.5) 0%,
                #000000 13%,
                #000000 100%
);
$gradient-mask-both: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.5) 0%,
                #000000 13%,
                #000000 87%,
                rgba(0, 0, 0, 0.5) 100%
);
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 100;
$font-family-sans: Nunito, sans;
$font-family-secondary-sans: Montserrat, sans;
$font-line-height-manus: 1.5;
$font-line-height-overline: 1.5;
$font-line-height-body: 1.5;
$font-line-height-title: 1.2;
$font-line-height-display: 1.2;
$font-letter-spacing-xs: -0.2;
$font-letter-spacing-s: 1;
$font-letter-spacing-m: 1.5;
$font-letter-spacing-l: 2;
$font-overline-xs: 11;
$font-overline-s: 12;
$font-overline-m: 13;
$font-overline-l: 14;
$font-overline-xl: 16;
$font-overline-2xl: 20;
$font-manus-l: 20;
$font-manus-xl: 40;
$font-body-xs: 11;
$font-body-s: 12;
$font-body-m: 13;
$font-body-l: 14;
$font-body-xl: 16;
$font-body-2xl: 18;
$font-body-3xl: 24;
$font-title-xs: 14;
$font-title-s: 16;
$font-title-m: 24;
$font-title-l: 28;
$font-title-xl: 32;
$font-title-2xl: 40;
$font-title-3xl: 50;
$font-display-xs: 40;
$font-display-s: 50;
$font-display-m: 56;
$font-display-l: 64;
$font-display-xl: 80;
$font-display-2xl: 104;
$fill-white-100: rgba(255, 255, 255, 1);
$fill-white-090: rgba(255, 255, 255, 0.9);
$fill-white-080: rgba(255, 255, 255, 0.8);
$fill-white-070: rgba(255, 255, 255, 0.7);
$fill-white-060: rgba(255, 255, 255, 0.6);
$fill-white-050: rgba(255, 255, 255, 0.5);
$fill-white-040: rgba(255, 255, 255, 0.4);
$fill-white-030: rgba(255, 255, 255, 0.3);
$fill-white-020: rgba(255, 255, 255, 0.2);
$fill-white-010: rgba(255, 255, 255, 0.1);
$fill-black-100: rgba(0, 0, 0, 1);
$fill-black-090: rgba(0, 0, 0, 0.9);
$fill-black-080: rgba(0, 0, 0, 0.8);
$fill-black-070: rgba(0, 0, 0, 0.7);
$fill-black-060: rgba(0, 0, 0, 0.6);
$fill-black-050: rgba(0, 0, 0, 0.5);
$fill-black-040: rgba(0, 0, 0, 0.4);
$fill-black-030: rgba(0, 0, 0, 0.3);
$fill-black-020: rgba(0, 0, 0, 0.2);
$fill-black-010: rgba(0, 0, 0, 0.1);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$color-argil: #5C3E2F;
$color-ocre: #CCB36E;
$color-black: #000000;
$color-grey: #5a5851;
$color-white: #ffffff;
$color-dark-silver: #b5b0af;
$color-off-white: #EDECE9;
$grid-desktop-xlarge-gutter: 24;
$grid-desktop-large-gutter: 24;
$grid-desktop-medium-gutter: 24;
$grid-tablet-xlarge-gutter: 24;
$grid-tablet-large-margin: 43;
$grid-tablet-large-gutter: 16;
$grid-tablet-medium-margin: 20;
$grid-tablet-medium-gutter: 16;
$grid-mobile-medium-margin: 20;
$grid-mobile-medium-gutter: 16;
$grid-root-margin: 12;
$grid-root-gutter: 12;

$header-desk-height: 90;
$header-mob-height: 68;

.button-header-burger {
  --burger-rotation-top: rotate(0deg);
  --burger-top-top: .125rem;
  --burger-rotation-bottom: rotate(0deg);
  --burger-bottom-top: .125rem;
  --burger-central-display: block;

  &.-open {
    --burger-rotation-top: rotate(45deg); // rotate(45deg);
    --burger-top-top: 52%;
    --burger-rotation-bottom: rotate(-45deg); //rotate(-45deg);
    --burger-bottom-top: 42%;
    --burger-central-display: none;
  }
}
.close{
  --burger-rotation-top: rotate(45deg); // rotate(45deg);
  --burger-top-top: 52%;
  --burger-rotation-bottom: rotate(-45deg); //rotate(-45deg);
  --burger-bottom-top: 42%;
  --burger-central-display: none;
}